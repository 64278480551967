import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Swiper from '../../../../components/ui/swiper'
import ImageOne from '../../../../data/images/blog/aug_2020_floor.jpg'
import ImageTwo from '../../../../data/images/blog/aug_2020_living_room.jpg'
import ImageThree from '../../../../data/images/blog/oct_2020_magnolia_staircase.jpg'
import ImageFour from '../../../../data/images/blog/oct_2020_rooms.jpg'
import ImageFive from '../../../../data/images/blog/sep_2020_room.jpg'
import ImageSix from '../../../../data/images/blog/sep_2020_room_downstairs.jpg'
import ImageSeven from '../../../../data/images/blog/sep_2020_room_downstairs2.jpg'
import ImageEight from '../../../../data/images/blog/sep_2020_room2.jpg'
import ImageNine from '../../../../data/images/blog/sep_2020_room3.jpg'
import ImageTen from '../../../../data/images/blog/sep_2020_staircase_down.jpg'
import Image13 from '../../../../data/images/blog/nov_2020_before_room.jpg'
import Image14 from '../../../../data/images/blog/nov_2020_downstairs.jpg'
import Image15 from '../../../../data/images/blog/nov_2020_floor.jpg'
import Image16 from '../../../../data/images/blog/nov_2020_gar.jpg'
import Image17 from '../../../../data/images/blog/nov_2020_garden.jpg'
import Image18 from '../../../../data/images/blog/nov_2020_garden_before4.jpg'
import Image19 from '../../../../data/images/blog/nov_2020_garden2.jpg'
import Image20 from '../../../../data/images/blog/nov_2020_garden3.jpg'
import Image21 from '../../../../data/images/blog/nov_2020_garden4.jpg'
import Image22 from '../../../../data/images/blog/nov_2020_kitchen.jpg'
import Image23 from '../../../../data/images/blog/nov_2020_living_room.jpg'
import Image24 from '../../../../data/images/blog/nov_2020_living_room_before.jpg'
import Image25 from '../../../../data/images/blog/nov_2020_living_room_before2.jpg'
import Image26 from '../../../../data/images/blog/nov_2020_living_room5.jpg'
import Image27 from '../../../../data/images/blog/nov_2020_parking_lot.jpg'
import Image28 from '../../../../data/images/blog/nov_2020_parking_lot2.jpg'
import Image29 from '../../../../data/images/blog/nov_2020_parking_lot3.jpg'
import Image30 from '../../../../data/images/blog/nov_2020_parking_lot4.jpg'
import Image31 from '../../../../data/images/blog/nov_2020_path.jpg'
import Image32 from '../../../../data/images/blog/nov_2020_path_bbefore.jpg'
import Image33 from '../../../../data/images/blog/nov_2020_room_bbefore.jpg'
import Image34 from '../../../../data/images/blog/nov_2020_room2.jpg'
import Image35 from '../../../../data/images/blog/nov_2020_staircase.jpg'
import { SectionWrap } from './style'

const SmoothTransitionSlider = ({ sliderSettings }) => {
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Heading as="h3" mb="60px" textalign="center">Gallery</Heading>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Swiper settings={sliderSettings}>
                            <div className="item">
                                <img src={ImageOne} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageTwo} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageThree} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageFour} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageFive} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageSix} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageSeven} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageEight} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageNine} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={ImageTen} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image13} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image14} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image15} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image16} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image17} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image18} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image19} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image20} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image21} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image22} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image23} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image24} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image25} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image26} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image27} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image28} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image29} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image30} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image31} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image32} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image33} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image34} alt="Slider" />
                            </div>
                            <div className="item">
                                <img src={Image35} alt="Slider" />
                            </div>
                        </Swiper>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

SmoothTransitionSlider.defaultProps = {
    sliderSettings: {
        slidesPerView: 'auto',
        freeMode: true,
        loop: true,
        autoplay: true,
        delay: 0,
        speed: 7000,
        paginationStyle: {
            mt: '50px'
        }
    }
}

export default SmoothTransitionSlider
